<template>
    <div class="flex">
        <RoomsList @roomSelected="changeRoom" />
        <MessagesContainer :selectedRoom="selectedRoom" />
    </div>
</template>
<script>
export default {
    components: {
        RoomsList:         () => import(/* webpackChunkName: "Messages/RoomsList" */ './components/RoomsList'),
        MessagesContainer: () => import(/* webpackChunkName: "Messages/MessagesContainer" */ './components/MessagesContainer'),
    },

    data() {
        return {
            selectedRoom: {
                title: '',
            },
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('messages.messages'));
        this.$store.commit('setActiveMenuItem', '7-2');
    },

    beforeDestroy() {
        this.$store.commit('setSelectedRoomId', null);
    },

    methods: {
        changeRoom(room) {
            this.selectedRoom = room;
            this.$store.commit('markRoomAsRead', room.id);
            this.$store.commit('setSelectedRoomId', room.id);
        },
    },
};
</script>
